.App {
  text-align: center;
  background: url(./images/bg3.jpg);
  /* background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; */
  width:100%;
  height: 100vh;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; 
display: flex;
justify-content: center;
align-content: center;
align-items: center;

}

.container{
  /* margin-top: 2.7rem;

  margin-right: 18rem; */
  

}
.video-wrapper{
  /* width:1rem; */
  display:flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width:100%;
  height: 100%;


}

.video-wrapper-blur{
  /* width:1rem; */
  display:flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width:100%;
  height: 100%;

  filter: blur(5px);
}
.shadow
{
    display:block;
    position:relative;
}

.shadow:before
{
    /* display:block;
    content:'';
    position:absolute;
  
    width:100%;
    height:100%;
    -moz-box-shadow:inset 0px 0px 10px 6px #eee1df;
    -webkit-box-shadow:inset 0px 0px 10px 6px #EEE1DF;
    box-shadow:inset 0px 0px 10px 10px #EEE1DF;
    z-index:0; */
}



button {
	background: rgba(238,225,223,0.8);
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  padding:1rem;
  border: 2px solid #EEE1DF;
 border-radius: 5px;
 position: absolute;
  left: 36%;
  top: 45%;
  color:#c86b6c;
  font-size: 16px;
  cursor: pointer;
}

button .bx .bx-pause {
  visibility: hidden!important;
}

.hide {
  visibility: hidden!important;
}
button:hover {
  background: #EEE1DF;
  color: #c86b6c !important;
}
video{
  width:90%;
  align-self: center;
  /* margin-top:10rem; */
  /* width:30rem; */
  /* border:1;
  
  border-radius: 1rem; */
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;

  border-radius: 5px;
  border-style: solid;
  border-width: 5px;
  border-color: #EEE1DF;
}

.controls{
  display: flex;
  justify-content: center;

}

.mute-btn {
  width: 2rem;
}

.play-btn{
  width:2rem;
}

.actions {
  background-color: #c86b6c;
  width:5rem;
  padding: .3rem;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  margin-top:2rem;
  border-radius: 5rem;
}


video::-webkit-media-controls-panel {
  background-color: transparent !important;
  box-shadow: none !important;
  background-image: linear-gradient(transparent, transparent) !important;
  display:none!important;

}
/* Could Use thise as well for Individual Controls */
video::-webkit-media-controls-play-button {
  display:none!important;
}

.locations {
  display: flex;
  justify-content: space-between;
  width:100%;
  margin-top: 2rem;
}

.location {
  display: flex;
  justify-content: center;
  width:100%;
  margin-top: 2rem;
}

.locations a {
color:#c86b6c;
}

.locations a:visited {
  color: #A95A5B;
}

.location a {
  color:#c86b6c;
  }
  
  .location a:visited {
    color: #A95A5B;
  }
video::-webkit-media-controls-volume-slider {  display:none!important;}

video::-webkit-media-controls-mute-button {   display:none!important;}

video::-webkit-media-controls-timeline { display: none;}

video::-webkit-media-controls-current-time-display { display: none;}


video::-webkit-media-controls-time-remaining-display {
  display:none!important;
}

video::-webkit-media-controls-fullscreen-button {
  display:none!important;
}

video::-webkit-media-text-track-container{
  display:none!important;
}